@media only screen and (max-width: 1300px) {

    .home {
        height: 100dvh;
        width: 100vw;
        align-items: center;
        justify-content: center;
    }

    .single_link-mobile {
        font-size: 1em;
        margin-right: 10px;
        cursor: pointer;
    }

    .single_link-mobile-last {
        margin-right: 10px;
    }

    .my-name-is {
        display: flex;
        justify-content: left;
        align-items: center;
        font-size: 1.2em;
        font-weight: 700;
        width: 100%;
        word-wrap: break-word;
        margin-bottom: 10px;
        margin-top: 10px;
    }

    .about {
        display: flex;
        flex-direction: column;
        height: calc(100vh - 50px);
        font-size: 1em;
    }

    .about-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 80vw;
        gap: 20px;
        font-size: 1.2em;
    }

    .prifile-photo {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40vw;
        border-radius: 50%;
        border: 5px solid var(--light-grey-color);
    }

    .about-text {
        text-align: justify;
        line-height: 1.2;
        gap: 5px;
        font-size: 1em;
        padding: 15px 20px 20px 20px;
        height: fit-content;
        width: 70vw;


    }

    .about-text p {
        font-size: 12px;
        margin-bottom: 1px;
        /* Adjust the value as needed */
        text-indent: 40px;
    }

    .sticky-header {
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        justify-content: center;
        align-items: center;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        background-color: var(--dark-color);
        z-index: 1000;
        height: 50px;
    }

    .navigation,
    .links {
        display: none;

    }


    .navigation-mobile {
        display: flex;
        flex-direction: row;


    }

    .single_nav_mobile {
        font-size: 1.2em;
        font-weight: 700;
        margin: 5px;
        cursor: pointer;
    }

    .links-mobile {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 20px;

    }

    .skills {
        height: calc(100vh - 50px);
        flex-direction: column;
        align-items: center;
    }

    .image-container {
        margin-top: 30px;
        display: flex;
        width: 65%;
        height: auto;
        position: relative;
        align-items: center;
    }



    .image-container img {
        align-items: center;
        width: 100%;
        height: auto;
        display: block;
    }

    .hover-info-container {
        display: none;
    }


    .hover-info-container-mobile {
        display: flex;
        flex-direction: column;
        align-items: center;

    }

    .hover-info {
        width: 100%;
        text-align: center;
        margin-left: 0px;
    }



    .skills-header {
        width: 100%;
        display: flex;
        justify-content: center;
        font-size: 1.2em;
        font-weight: 700;
        margin-bottom: 10px;
        margin-top: 10px;
        color: var(--primary-color);
    }

    .skill {
        display: grid;
        grid-template-columns: 50px auto;
        width: fit-content;
        max-width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        margin-bottom: 20px;
    }

    .skill-img {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 35px;
        height: 35px;
    }

    @keyframes moveScanner {
        0% {
            transform: translateX(10px);
        }

        50% {
            transform: translateX(-70dvw) translateX(-50px);
        }

        100% {
            transform: translateX(10px);
        }
    }




    .projects {
        display: flex;
        justify-content: center;
        height: auto;
        flex-direction: column;
        gap: 20px;
    }

    .separator-container {
        height: 30px;
    }

    .video-responsive {
        aspect-ratio: 16 / 9;

        width: 80vw;
        height: 45vw;
    }


    .project-container {
        display: flex;
        flex-direction: column;
        width: 80vw;
        gap: 20px;
    }



    .image-container {
        width: 100%;
    }



    .video-desc-container {
        display: grid;
        grid-template-rows: auto auto;
        width: 100%;
        gap: 10px;
        align-content: center;
    }

    .project-header {
        display: none;
    }

    .project-header-mobile {
        display: flex;
        font-size: 1.2em;
        font-weight: 700;
    }

    .project-description {
        font-size: 1em;
        text-align: justify;
        overflow: hidden;
    }

    .project-description ul {
        list-style: none;
        padding-left: 20px;
        color: var(--light-grey-color);
    }

    .project-description li {
        position: relative;
        margin-bottom: 10px;
        color: var(--light-grey-color);
    }

    .project-description li::before {
        content: "✦";
        color: var(--light-grey-color);
        /* or any other character you prefer */
        position: absolute;
        left: -20px;
    }

    .project-skills {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 5px;
        overflow: hidden;

    }

    .project-skill {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
        height: 20px;
        width: auto;
        border-radius: 10px;
        background-color: var(--grey-color);
    }

    .contact {

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        height: 100vh;
        gap: 20px;
    }

    .contact-form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 80vw;
        gap: 10px;
    }

    .contact-header {
        display: flex;
        justify-content: left;
        align-items: center;
        font-size: 1.8em;
        font-weight: 700;
        width: auto;
        margin-bottom: 10px;
        margin-top: 10px;
    }

    .small-field,
    .large-field {
        width: 80vw;
    }

    .skills-container {
        padding-left: 0;
        flex-direction: column;
        justify-content: start;
        align-items: flex-start;

    }

    .skills-container-default {
        padding: 0px;
        justify-content: center;
        align-items: start;
        width: 60vw;

    }

    .arrows {
        display: flex;
        width: 50vw;
        justify-content: space-between;
    }

    h1 {
        margin-block-start: 00px;
    }

}