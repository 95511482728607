@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap');

/* 222831  */
:root {
    --dark-color: #222831;
    --dark-color2: #223122;
    --grey-color: #393E46;
    --primary-color: #00ADB5;
    --light-grey-color: #EEEEEE;
    width: 100%;
}

body {
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--dark-color);
    color: var(--light-grey-color);
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
    width: 100%;
}

a:link,
a:visited {
    color: inherit;
    text-decoration: none;
}

body::-webkit-scrollbar {
    display: none;
}

.master-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sticky-header {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    position: sticky;
    top: 0;
    background-color: var(--dark-color);
    z-index: 1000;
    height: 50px;
    width: 80vw;
}

.navigation,
.links {
    display: flex;
    flex-direction: row;

}

.navigation {
    display: flex;
    flex-direction: row;
    grid-column: 2;
    margin: 0 20px;
}

.active-nav {
    color: var(--primary-color);
}

.links {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-column: 3;
    justify-self: end;
    margin-right: 20px;
}

.single_nav {
    font-size: 1.2em;
    font-weight: 700;
    padding: 5px;
    margin-left: 5px;
    margin-right: 5px;
    border: 2px solid var(--light-grey-color);
    cursor: pointer;
}

.single_nav-left {
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    padding-left: 10px;
}

.single_nav-right {
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    padding-right: 10px;
}

.single_link {
    font-size: 1.5em;
    margin-right: 10px;
    cursor: pointer;
}

.single_link-last {
    margin-right: 20px;
}


.home {
    height: calc(100vh - 50px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: auto;
}


.my-name-is {
    display: flex;
    justify-content: left;
    align-items: center;
    font-size: 2.8em;
    font-weight: 700;
    width: auto;
    margin-bottom: 10px;
    margin-top: 10px;
}

.project-header-mobile {
    display: none;
}

.skills {
    display: flex;
    flex-direction: row;
    height: calc(100vh - 50px);
    align-items: center;
    justify-content: center;
    align-self: center;
    gap: 20px;
    /* Adds consistent spacing between items */
    width: 80vw;
}



.front-skills {
    display: none;
}

.tools-skills {
    display: none;
}

.image-container {
    display: flex;
    width: 100%;
    flex-direction: row;
    position: relative;
    align-items: center;
    justify-content: center;
}




.image-container img {
    display: flex;
    align-items: center;
    width: 100%;
    margin: auto;
    position: relative;

}



.overlay1 {
    position: absolute;
    left: 0;
    width: 34%;
    height: 100%;
}

.overlay2 {
    position: absolute;
    left: 34%;
    width: 27%;
    height: 100%;

}

.overlay3 {
    position: absolute;
    left: 61%;
    width: 39%;
    height: 100%;
}

.overlay1:hover .skills-header {
    color: mediumblue;
}

.skills-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 15vw;
    height: 100%;
    width: 20vw;
    gap: 10px;
}

.skills-container-default {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-left: 15vw;
    height: 100%;
    width: 20vw;
    gap: 10px;
}


.skills-header {
    width: 100%;
    display: flex;
    justify-content: start;
    font-size: 2em;
    font-weight: 700;
    margin-bottom: 30px;
    margin-top: 30px;
    color: var(--primary-color);
}

.skill {
    display: grid;
    grid-template-columns: 50px auto;
    width: fit-content;
    max-width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
}

.skill-img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
}


.small-plane-img {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 60px;
}


.hover-info {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-left: 15px;
}

.hover-info-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.hover-info-container-mobile {
    display: none;
}

.scanner {
    width: 10vw;
    height: 20vw;
    border: 1px solid var(--primary-color);
    animation: moveScanner 5s ease-in-out infinite;
    /* Animation name and duration */
}

@keyframes moveScanner {
    0% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(-60dvw) translateX(-70px);
    }

    100% {
        transform: translateX(0);
    }
}

.projects {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}

.project-container {
    display: grid;
    grid-template-columns: 6fr 4fr;
    justify-content: start;
    height: auto;
    width: 80vw;
    gap: 20px;
    margin-top: 20px;
}



.video-responsive {
    aspect-ratio: 16 / 9;

    width: 100%;
    max-height: 80%;
    /* Prevents overflow */
    margin: 0 auto;

}



.video-desc-container {
    display: grid;
    width: 100%;
    gap: 10px;
}

.project-header {
    font-size: 1.5em;
    font-weight: 700;
}

.project-description {
    font-size: 1em;
    text-align: justify;
    overflow: hidden;
}

.project-skills {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
    overflow: hidden;
}

.project-skill {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    height: 15px;
    width: auto;
    border-radius: 10px;
    background-color: var(--grey-color);
}

.source-code-container {
    display: flex;
    align-items: center;
    justify-content: center;

}

.source-code {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    width: 150px;
    border-radius: 20px;
    border: 2px solid var(--light-grey-color);
    color: var(--light-grey-color);
    background-color: var(--dark-color);
    font-size: 1.2em;
    font-weight: 700;
    cursor: pointer;
    padding: 1px 10px;

}

.source-code:hover {
    background-color: var(--primary-color);
    color: var(--dark-color);
    border: 2px solid var(--primary-color);
}

.about {
    height: calc(100vh - 50px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.about-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 80vw;
    gap: 5vw;
    font-size: 1.2em;
}

.photo-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.prifile-photo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    border-radius: 50%;
    border: 5px solid var(--light-grey-color);
}

.about-text {
    width: 90%;
    text-align: justify;
    line-height: 1.3;
    gap: 5px;
    padding: 4vh;
    text-indent: '40px';
    border: 2px solid var(--primary-color);
    border-radius: 0px 40px 40px 40px;
}

.about-text p {
    text-indent: 40px;
}


.contact {
    height: calc(100vh - 50px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.contact-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    gap: 10px;
}

.small-field {
    height: 30px;
    width: 900px;
    padding: 5px 10px;
    font-size: 1em;
    font-family: 'Montserrat', sans-serif;
    border-radius: 20px;
}

.large-field {
    height: 100px;
    width: 900px;
    padding: 10px 10px;
    font-size: 1em;
    font-family: 'Montserrat', sans-serif;
    border-radius: 20px;
}

.button {
    height: 40px;
    width: 120px;
    border-radius: 20px;
    border: 2px solid var(--light-grey-color);
    color: var(--light-grey-color);
    background-color: var(--dark-color);
    font-size: 1.2em;
    font-weight: 700;
    cursor: pointer;
}

.navigation-mobile {
    display: none;
}

.links-mobile {
    display: none;
}

.button:hover {
    background-color: var(--primary-color);
    color: var(--dark-color);
    border: 2px solid var(--primary-color);
}

.single_nav:hover {
    background-color: var(--primary-color);
    color: var(--dark-color);
    border: 2px solid var(--primary-color);
}

.single_link:hover,
.single_link-last:hover {
    color: var(--primary-color);
}

.contact-header {
    display: flex;
    justify-content: left;
    align-items: center;
    font-size: 1.8em;
    font-weight: 700;
    width: auto;
    margin-bottom: 10px;
    margin-top: 10px;
}

h1 {
    margin-block-start: 100px;
    margin-block-end: 0px;
    font-size: 1.3em;
}

h2 {
    margin-block-start: 1em;
    margin-block-end: 0px;
    font-size: 1.1em;
}

p {
    font-size: 1em;
}



.project-image {
    border-radius: 50% 20% / 10% 40%;
}